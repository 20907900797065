
import { defineComponent, ref, onMounted } from "vue";
import addCategoriesModal from "@/views/catalog/categories/AddCategoriesModal.vue";
import FilterDropdwon from "@/views/catalog/categories/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ApiCategories } from "@/core/api";
import _ from "lodash";

interface Categories {
  id: number;
  name: string;
  code: number;
  status: number;
  category_items: Array<any>;
  parent_id: number;
}
interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "Categories",
  components: {
    addCategoriesModal,
    FilterDropdwon,
  },
  setup() {
    const loading = ref(true);
    const tableData = ref<Array<Categories>>([]);
    const selectData = ref<Array<Categories>>([]);
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);

    const tableHeader = ref([
      {
        name: "Categories Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Parent Categories ID",
        key: "parent_id",
        sortable: true,
      },
      {
        name: "Parent Categories Name",
        key: "_parent_name",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageTitle("Categories");
      getCategoriesList();
      getSelectList();
    });
    const load = (row, treeNode, resolve) => {
      resolve(row.category_items);
    };
    const getSelectList = (id?: string) => {
      ApiCategories.getCategoriesSelectInfo({
        id: id,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            selectData.value.splice(0, selectData.value.length, ...data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getCategoriesList = (
      search?: string,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiCategories.getCategoriesList({
        search_value: search,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "") {
          filterArr.push({
            field: item,
            value: val[item],
            condition: "eq",
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      getCategoriesList(search.value, filterOptions.value);
    };

    const handleFilterReset = () => {
      resetFilter();
      getCategoriesList(search.value, filterOptions.value);
    };

    const resetFilter = () => {
      // let sts = {
      //   field: "",
      //   value: "",
      // };
      // filterOptions.value.splice(0, filterOptions.value.length, sts);
      filterOptions.value = [];
    };

    const debounceSearch = _.debounce(getCategoriesList, 1000);

    const searchItems = () => {
      debounceSearch(search.value, filterOptions.value);
    };

    const UpdatesList = () => {
      getCategoriesList(search.value, filterOptions.value);
      getSelectList();
    };
    return {
      load,
      loading,
      tableHeader,
      tableData,
      selectData,
      search,
      getCategoriesList,
      searchItems,
      handleFilter,
      handleFilterReset,
      UpdatesList,
    };
  },
});
