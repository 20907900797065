
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiCategories } from "@/core/api";
import mixin from "@/mixins";

interface propData {
  checkStrictly: boolean;
  value: string;
  label: string;
  children: string;
  lazy: boolean;
  lazyLoad: any;
}

export default defineComponent({
  name: "add-categories-modal",
  components: {},
  emits: ["update-list", "reset-form"],
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const tableArr = ref(props.tableData);
    const formRef = ref<null | HTMLFormElement>(null);
    const addCategoriesModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const propData = ref<propData>({
      checkStrictly: true,
      value: "id",
      label: "name",
      children: "category_items",
      lazy: true,
      lazyLoad(node, resolve) {
        resolve(node.children);
      },
    });
    const formData = ref({
      status: 10,
      name: "",
      code: "",
      parent_id: 0,
      is_public: 0,
      category_image: "",
      description: "",
      seo_title: "",
      seo_description: "",
      sort: "",
      google_product_category_id: "",
    });

    const countryOptions = ref([]);
    const rules = ref({
      name: [
        {
          required: true,
          message: "Categories Name is required",
          trigger: "change",
        },
      ],
      code: [
        {
          required: true,
          message: "Categories code is required",
          trigger: "change",
        },
      ],
      is_public: [
        {
          required: true,
          message: "Is Public is required",
          trigger: "change",
        },
      ],
      parent_id: [
        {
          required: false,
          message: "Parent Category ID is required",
          trigger: "change",
        },
      ],
    });
    const parentChange = (value) => {
      if (value) {
        formData.value.parent_id = value[value.length - 1];
      } else {
        formData.value.parent_id = 0;
      }
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiCategories.addCategories(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addCategoriesModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getCountryOptions = () => {
      ApiBase.getCountryData()
        .then(({ data }) => {
          if (data.code == 0) {
            countryOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      hideModal(addCategoriesModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      getCountryOptions();
    });

    return {
      loading,
      tableArr,
      propData,
      formData,
      rules,
      formRef,
      addCategoriesModalRef,
      submitButton,
      countryOptions,
      parentChange,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
