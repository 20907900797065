
import { defineComponent, ref, onMounted } from "vue";
import { number } from "yup/lib/locale";

interface Filter {
  code: string;
  is_public: string | number;
  parent_id: number;
  status: string | number;
}

interface propData {
  checkStrictly: boolean;
  value: string;
  label: string;
  children: string;
  lazy: boolean;
  lazyLoad: any;
}

export default defineComponent({
  name: "dropdown-1",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      code: "",
      is_public: "",
      parent_id: 0,
      status: "",
    });
    const tableArr = ref(props.tableData);
    const propData = ref<propData>({
      checkStrictly: true,
      value: "id",
      label: "name",
      children: "category_items",
      lazy: true,
      lazyLoad(node, resolve) {
        resolve(node.children);
      },
    });
    const parentChange = (value) => {
      if (value) {
        data.value.parent_id = value[value.length - 1];
      } else {
        data.value.parent_id = 0;
      }
    };
    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      data,
      tableArr,
      propData,
      formRef,
      submit,
      handleReset,
      parentChange,
    };
  },
});
